<template>
  <div>
    <div>UID: {{uidRef}}</div>
    <div>Domain: {{domain}}</div>
    <div>FE database: {{frontendDb}}</div>
    <div>BE database: {{backendDb}}</div>
    <div>Storage: {{storage}}
      <span v-if="!isLoggedIn">unknown, please login</span>
      <span v-else-if="storageIsProd">(PROD)</span>
      <span v-else>(DEV)</span>
    </div>
    <div>
      <input type="checkbox" id="forceProdStorage" v-model="forceProdStorage" @change="handleForceProdStorage">
      <label for="forceProdStorage">&nbsp;Force prod storage (refresh after changing!)</label>
    </div>
    <div>iFrame: {{iframe}}</div>
    <div>iFrame Info:</div>
    <div><DebugIFrameInfo></DebugIFrameInfo></div>
    <div>Stripe backend:
      <span v-if="loggedIn">{{stripeEnvironmentIsTest ? 'test' : 'live'}}</span>
      <span v-else>not logged in.</span>
      <span v-if="stripeIsTogglable">&nbsp(<a v-if="!toggleDisabled" href="javascript:void(0)" @click="toggleStripeBackend">toggle</a>)</span>
      <span v-else>&nbsp;(not toggleable)</span>
    </div>
    <div class="ml-2" style="color:#ccc">
      {{stripeMsg}}
    </div>
  </div>
</template>

<script setup>
import callFirebaseFunction from "~/helpers/callFirebaseFunction.js";
import {dbRef} from "~/helpers/dbRef.js";
import useCurrentDomain from "~/composables/useCurrentDomain.js";
import {getIframeDomain} from "~/helpers/helpers.js";
import DebugIFrameInfo from "~/components/debug/DebugIFrameInfo.vue";

const database = useDatabase();
const currentUser = useCurrentUser();

const domain = ref('')
const frontendDb = ref('')
const backendDb = ref('')
const iframe = ref('')
const loggedIn = ref(true)
const stripeMsg = ref('')
const stripeIsTogglable = ref(false);
const uidRef = ref('')
const storage = ref('')
const toggleDisabled = ref(false);

const stripeEnvironmentIsTest = ref(false);
const forceProdStorage = ref(localStorage.getItem('force_prod_storage_bucket') === 'true')


onMounted(async () => {
  domain.value = useCurrentDomain()
  iframe.value = getIframeDomain()
  frontendDb.value = dbRef(database, '/').toString();
  const response = await callFirebaseFunction('get_database_type', {});
  backendDb.value = response.data.database_type;


  const uid = currentUser?.value?.uid;
  if (uid){
    uidRef.value = uid;

    // only valid if logged in
    storage.value = (await callFirebaseFunction('get_storage_bucket_info')).data.bucket;

    const stripeStatus = await callFirebaseFunction('stripe_get_environment_info', {});
    stripeIsTogglable.value = stripeStatus.data.is_toggleable;
    stripeEnvironmentIsTest.value = stripeStatus.data.uses_test_environment;
    stripeMsg.value = `Can only toggle stripe backend if account has never visited checkout.`
    console.log("stripeStatus", stripeStatus);
  }
  else{
    loggedIn.value = false;
  }
});

const storageIsProd = computed(() => {
  return storage.value === 'simmercdn-bb'}
);

const isLoggedIn = computed(() => {
  return !!uidRef.value;
})

const toggleStripeBackend = async () => {
  await callFirebaseFunction('stripe_toggle_environment');

  toggleDisabled.value = true;
  stripeMsg.value = 'Stripe backend toggled. Refresh the page.';
}

const handleForceProdStorage = () => {
  if (forceProdStorage.value) {
    localStorage.setItem('force_prod_storage_bucket', 'true')
  } else {
    localStorage.removeItem('force_prod_storage_bucket')
  }
}

</script>

