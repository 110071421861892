<template>
  <iframe :src="iframeUrl" class="debug-iframe"></iframe>
</template>
<script setup>
import {getIframeDomain} from "~/helpers/helpers.js";
const iframeUrl = getIframeDomain() + '/simmer-iframe-checker';
</script>
<style scoped>
.debug-iframe{
  width:260px;
  height:20px;
  border:0;
}
</style>