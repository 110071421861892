import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useFirebaseApp } from 'vuefire'

export default async function callFirebaseFunction(functionName, args) {
    const firebaseApp = useFirebaseApp();
    const functions = getFunctions(firebaseApp);

    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    if (localStorage.getItem('force_prod_storage_bucket') === 'true'){
        args = args || {};
        args.force_prod_storage_bucket = true;
    }

    const myFunction = httpsCallable(functions, functionName);
    return await myFunction(args);
}
