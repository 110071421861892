<template>
  <div class="d-flex flex-column align-center justify-center" style="height: 100vh">
    <h1 class="text-h2 mb-4">404</h1>
    <p class="text-h5 mb-8">Page not found</p>
    <v-btn to="/" color="primary" class="mb-4">
      Return Home
    </v-btn>
    <div class="pa-3 mt-5" style="border:1px solid #cd1c55">
      <h4>March 2025: SIMMER Relauch Hiccup?</h4>
      <p>Try your URL on our legacy site: <a :href="legacyUrl" target="_blank">{{ legacyUrl }}</a>. <span class="font-weight-bold">You may need to login again.</span></p>
      <p>We'd appreciate a reachout letting us know what you were doing: <a href="mailto:support@simmer.io">support@simmer.io</a> | <a href="https://discord.gg/2wGJeH6">discord</a></p>

    </div>
    <DebugToolbar></DebugToolbar>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'

useHead({title: '404 - SIMMER.io'})

const props = defineProps({
  error: Object
})

const route = useRoute()
const legacyUrl = `https://legacy.simmer.io${route.fullPath}`

console.log(props.error)
</script>
