<template>
  <div>
    <v-app class="app-background">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </v-app>
  </div>
</template>

<style scoped>
/* todo lowpri:
.app-background {
  background-image: url('http://cdn.backgroundhost.com/backgrounds/subtlepatterns/black_denim.png');
}
*/

/* without this, vuetify will "push" the content of main down by 64px after loading */
:deep(.v-main) {
  transition: none !important;
  --v-layout-top: 64px !important;
}
</style>

