export default function useCurrentDomain() {
    if (process.client) {
        return window.location.origin;
    }

    // Include x-forwarded-port in your header lookup
    const headers = useRequestHeaders(['host', 'x-forwarded-proto', 'x-forwarded-port']);
    const protocol = headers['x-forwarded-proto'] || 'http';

    // Prefer the x-forwarded-port header if provided, fallback to the port in host header
    const forwardedPort = headers['x-forwarded-port'];
    const [host, portFromHost] = headers.host.split(':');
    const port = forwardedPort || portFromHost;

    // Only add the port if it exists and is not the default port for the protocol
    const portString = port ? `:${port}` : '';
    return `${protocol}://${host}${portString}`;
}

export function useCurrentHost(){
    if (process.client) {
        return window.location.host;
    }
    const headers = useRequestHeaders(['host', 'x-forwarded-proto']);
    const [host] = headers.host.split(':');
    return host;
}

export function useIsSMG(){
    const currentDomain = useCurrentDomain();
    // console.log('SUDOKEN', currentDomain);

    return currentDomain === 'http://localhost:5052';
}
