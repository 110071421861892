import payload_plugin_qLmFnukI99 from "/Users/roccob/dev/new-simmer/hosting/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_ALLXFGgbCP from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/roccob/dev/new-simmer/hosting/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/roccob/dev/new-simmer/hosting/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/Users/roccob/dev/new-simmer/hosting/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_pNVVgm7Ud2 from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/roccob/dev/new-simmer/hosting/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_gJ7MNBxzVz from "/Users/roccob/dev/new-simmer/hosting/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.js";
import vuefire_plugin_8cq4FnzXKb from "/Users/roccob/dev/new-simmer/hosting/.nuxt/vuefire-plugin.mjs";
import vue_cropperjs_BH69WHUB3l from "/Users/roccob/dev/new-simmer/hosting/plugins/vue-cropperjs.js";
import vuetify_7h9QAQEssH from "/Users/roccob/dev/new-simmer/hosting/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_pNVVgm7Ud2,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_gJ7MNBxzVz,
  vuefire_plugin_8cq4FnzXKb,
  vue_cropperjs_BH69WHUB3l,
  vuetify_7h9QAQEssH
]