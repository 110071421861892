
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as editvUwpqKdafPMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/edit.vue?macro=true";
import { default as indexZl5gbCboaAMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/index.vue?macro=true";
import { default as uploadOHGyjsykHnMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/upload.vue?macro=true";
import { default as indexqaLGxdhiYVMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/@[user]/index.vue?macro=true";
import { default as admin6WY8gJD7MXMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/admin.vue?macro=true";
import { default as indexDCaKMfNYMeMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/articles/[article]/index.vue?macro=true";
import { default as index2hsJXTDiiHMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/articles/index.vue?macro=true";
import { default as brandingarCso1YJl6Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/branding.vue?macro=true";
import { default as _91collection_page_93SmIkZ8uL6vMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/[collection_page].vue?macro=true";
import { default as edity2fdBf3EjWMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/edit.vue?macro=true";
import { default as indexFYb7GIVuU6Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/index.vue?macro=true";
import { default as commentSSOe6T0YQn2XRMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/commentSSO.vue?macro=true";
import { default as curationlDvCM6PwinMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/curation.vue?macro=true";
import { default as data7Cdd98MRPBMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/data.vue?macro=true";
import { default as debugReloadky8wje5iDnMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/debugReload.vue?macro=true";
import { default as blankqz6aYmIf5kMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/deck/blank.vue?macro=true";
import { default as indexYdtI7CM2kKMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/deck/index.vue?macro=true";
import { default as faqcQSSQuW2ahMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/faq.vue?macro=true";
import { default as galaxyYq5uBgZNp8Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/galaxy.vue?macro=true";
import { default as gdprBsGMzpODmJMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/gdpr.vue?macro=true";
import { default as impersonaterDbl53KTfmMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/impersonate.vue?macro=true";
import { default as index3dayLn6vb3Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/index.vue?macro=true";
import { default as _91latest_page_93OfR6trTdJAMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/latest/[latest_page].vue?macro=true";
import { default as indexaFGNqGdQlPMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/latest/index.vue?macro=true";
import { default as _91loader_id_93HXW81CROQ2Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/loading/[loader_id].vue?macro=true";
import { default as loginrr8yzQaOB3Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/login.vue?macro=true";
import { default as my_collectionsM6SwDcugKWMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/my_collections.vue?macro=true";
import { default as my_loading_screenspZCAWveXvBMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/my_loading_screens.vue?macro=true";
import { default as my_uploadsjeeQ8rJaIDMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/my_uploads.vue?macro=true";
import { default as playgroundBLS9pvvMh2Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/playground.vue?macro=true";
import { default as pricingFTc4ZeisqyMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/pricing.vue?macro=true";
import { default as privacyiRlpWKhfEkMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/privacy.vue?macro=true";
import { default as profileAb3wUvG4tpMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/profile.vue?macro=true";
import { default as simmerConnecttKfG90wbCkMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/simmerConnect.vue?macro=true";
import { default as staging7NFnGCw0smMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/staging.vue?macro=true";
import { default as subscriptionsmDROmikznKMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/subscriptions.vue?macro=true";
import { default as termsCvweEinNASMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/terms.vue?macro=true";
import { default as framez7kmtUvJ0RMeta } from "/Users/roccob/dev/new-simmer/hosting/pages/upload/frame.vue?macro=true";
import { default as indexuXumqv9BR7Meta } from "/Users/roccob/dev/new-simmer/hosting/pages/upload/index.vue?macro=true";
export default [
  {
    name: "@user-game-edit",
    path: "/@:user()/:game()/edit",
    meta: editvUwpqKdafPMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/edit.vue")
  },
  {
    name: "@user-game",
    path: "/@:user()/:game()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/index.vue")
  },
  {
    name: "@user-game-upload",
    path: "/@:user()/:game()/upload",
    meta: uploadOHGyjsykHnMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/@[user]/[game]/upload.vue")
  },
  {
    name: "@user",
    path: "/@:user()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/@[user]/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: admin6WY8gJD7MXMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/admin.vue")
  },
  {
    name: "articles-article",
    path: "/articles/:article()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/articles/[article]/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/articles/index.vue")
  },
  {
    name: "branding",
    path: "/branding",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/branding.vue")
  },
  {
    name: "c-collection-collection_page",
    path: "/c/:collection()/:collection_page()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/[collection_page].vue")
  },
  {
    name: "c-collection-edit",
    path: "/c/:collection()/edit",
    meta: edity2fdBf3EjWMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/edit.vue")
  },
  {
    name: "c-collection",
    path: "/c/:collection()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/c/[collection]/index.vue")
  },
  {
    name: "commentSSO",
    path: "/commentSSO",
    meta: commentSSOe6T0YQn2XRMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/commentSSO.vue")
  },
  {
    name: "curation",
    path: "/curation",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/curation.vue")
  },
  {
    name: "data",
    path: "/data",
    meta: data7Cdd98MRPBMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/data.vue")
  },
  {
    name: "debugReload",
    path: "/debugReload",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/debugReload.vue")
  },
  {
    name: "deck-blank",
    path: "/deck/blank",
    meta: blankqz6aYmIf5kMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/deck/blank.vue")
  },
  {
    name: "deck",
    path: "/deck",
    meta: indexYdtI7CM2kKMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/deck/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/faq.vue")
  },
  {
    name: "galaxy",
    path: "/galaxy",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/galaxy.vue")
  },
  {
    name: "gdpr",
    path: "/gdpr",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/gdpr.vue")
  },
  {
    name: "impersonate",
    path: "/impersonate",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/impersonate.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/index.vue")
  },
  {
    name: "latest-latest_page",
    path: "/latest/:latest_page()",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/latest/[latest_page].vue")
  },
  {
    name: "latest",
    path: "/latest",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/latest/index.vue")
  },
  {
    name: "loading-loader_id",
    path: "/loading/:loader_id()",
    meta: _91loader_id_93HXW81CROQ2Meta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/loading/[loader_id].vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/login.vue")
  },
  {
    name: "my_collections",
    path: "/my_collections",
    meta: my_collectionsM6SwDcugKWMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/my_collections.vue")
  },
  {
    name: "my_loading_screens",
    path: "/my_loading_screens",
    meta: my_loading_screenspZCAWveXvBMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/my_loading_screens.vue")
  },
  {
    name: "my_uploads",
    path: "/my_uploads",
    meta: my_uploadsjeeQ8rJaIDMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/my_uploads.vue")
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/playground.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/privacy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileAb3wUvG4tpMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/profile.vue")
  },
  {
    name: "simmerConnect",
    path: "/simmerConnect",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/simmerConnect.vue")
  },
  {
    name: "staging",
    path: "/staging",
    meta: staging7NFnGCw0smMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/staging.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    meta: subscriptionsmDROmikznKMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/subscriptions.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/terms.vue")
  },
  {
    name: "upload-frame",
    path: "/upload/frame",
    meta: framez7kmtUvJ0RMeta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/upload/frame.vue")
  },
  {
    name: "upload",
    path: "/upload",
    meta: indexuXumqv9BR7Meta || {},
    component: () => import("/Users/roccob/dev/new-simmer/hosting/pages/upload/index.vue")
  }
]