<template>
  <div v-if="isVisible" :class="['debug-toolbar', { 'minimized': isMinimized }]">
    <div v-if="!isMinimized" class="toolbar-content">
      <div>
        <span class="font-weight-bold">SIMMER v{{version}}</span>
        <span class="toolbar-controls">
            (<a href="#" @click.prevent="expandAll">expand</a> |
            <a href="#" @click.prevent="collapseAll">collapse</a>)
        </span>
      </div>

      <button class="minimize-button" @click="toggleMinimize">_</button>
      <button class="close-button" @click="dismissToolbar">×</button>

      <div class="section-header" @click="toggleSection('environment')">
        <span class="expand-icon">{{ expandedSections.environment ? '▼' : '▶' }}</span>
        <span class="font-weight-bold">Environment Info</span>
      </div>
      <div v-if="expandedSections.environment">
        <DebugInfo></DebugInfo>
      </div>

      <div class="section-header" @click="toggleSection('publicLinks')">
        <span class="expand-icon">{{ expandedSections.publicLinks ? '▼' : '▶' }}</span>
        <span class="font-weight-bold">Public Links</span>
      </div>
      <div v-if="expandedSections.publicLinks">
        <DebugLink to="/"></DebugLink>
        <DebugLink to="/latest"></DebugLink>
        <DebugLink to="/@TheRoccoB"></DebugLink>
        <DebugLink to="/@TheRoccoB/flappy-bird-style"></DebugLink>
        <DebugLink to="/@TheRoccoB/~7cd8afbb-f845-02e4-913e-5f0428609d35"></DebugLink>
        <DebugLink to="/c/simmerjam2"></DebugLink>
      </div>

      <div class="section-header" @click="toggleSection('authLinks')">
        <span class="expand-icon">{{ expandedSections.authLinks ? '▼' : '▶' }}</span>
        <span class="font-weight-bold">Auth Links</span>
      </div>
      <div v-if="expandedSections.authLinks">
        <DebugLink to="/profile"></DebugLink>
        <DebugLink to="/upload"></DebugLink>
        <DebugLink to="/staging"></DebugLink>
        <DebugLink to="/data"></DebugLink>
        <DebugLink to="/@TheRoccoB/flappy-bird-style/edit"></DebugLink>
        <DebugLink to="/@TheRoccoB/~7cd8afbb-f845-02e4-913e-5f0428609d35/edit"></DebugLink>
        <DebugLink to="/@TheRoccoB/flappy-bird-style/upload"></DebugLink>
        <DebugLink to="/@TheRoccoB/~7cd8afbb-f845-02e4-913e-5f0428609d35/upload"></DebugLink>
        <DebugLink to="/c/simmerjam2/edit"></DebugLink>
      </div>

      <div class="section-header" @click="toggleSection('notFound')">
        <span class="expand-icon">{{ expandedSections.notFound ? '▼' : '▶' }}</span>
        <span class="font-weight-bold">404s</span>
      </div>
      <div v-if="expandedSections.notFound">
        <DebugLink to="/c"></DebugLink>
        <DebugLink to="/c/non-existent-collection"></DebugLink>
        <DebugLink to="/non-existent-top-level"></DebugLink>
        <DebugLink to="/@NonExistentUser"></DebugLink>
        <DebugLink to="/@NonExistentUser/non-existent-game"></DebugLink>
      </div>

      <div class="section-header" @click="toggleSection('debug')">
        <span class="expand-icon">{{ expandedSections.debug ? '▼' : '▶' }}</span>
        <span class="font-weight-bold">Debug Only</span>
      </div>
      <div v-if="expandedSections.debug">
        <DebugLink to="/impersonate"></DebugLink>
        <DebugLink to="/playground"></DebugLink>
        <DebugLink to="/debugReload"></DebugLink>
      </div>

      <div class="route-input-section">
        <input
            v-model="routeInput"
            placeholder="Enter route (e.g. /latest)"
            class="route-input"
        >
        <button @click="goToRoute" class="go-button">Go</button>
      </div>

    </div>
    <div v-else class="minimized-content" @click="toggleMinimize">
      &#x1f6e0;&#xfe0f; <!-- toolbox emoji -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import version from '~/helpers/version.js'

const isVisible = ref(false)
const isMinimized = ref(false)
const shiftKeyPresses = ref(0)
const lastKeyPressTime = ref(0)
const routeInput = ref('')

const goToRoute = () => {
  if (routeInput.value) {
    navigateTo(routeInput.value)
    routeInput.value = ''
  }
}

const expandedSections = ref({
  environment: false,
  publicLinks: false,
  authLinks: false,
  notFound: false,
  debug: false
})

const toggleMinimize = () => {
  isMinimized.value = !isMinimized.value
  if (process.client) {
    localStorage.setItem('debugToolbarMinimized', isMinimized.value)
  }
}

const toggleSection = (section) => {
  expandedSections.value[section] = !expandedSections.value[section]
  if (process.client) {
    localStorage.setItem('debugToolbarSections', JSON.stringify(expandedSections.value))
  }
}

// hold ALT / Option and press Shift 5 times to toggle the toolbar
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.getModifierState('Alt')) {
    const currentTime = Date.now()
    if (currentTime - lastKeyPressTime.value > 500) {
      shiftKeyPresses.value = 1
    } else {
      shiftKeyPresses.value++
    }
    lastKeyPressTime.value = currentTime

    if (shiftKeyPresses.value === 5) {
      isVisible.value = !isVisible.value
      if (process.client) {
        localStorage.setItem('debugToolbarVisible', isVisible.value)
      }
      shiftKeyPresses.value = 0
    }
  }
}

const expandAll = () => {
  Object.keys(expandedSections.value).forEach(section => {
    expandedSections.value[section] = true
  })
  if (process.client) {
    localStorage.setItem('debugToolbarSections', JSON.stringify(expandedSections.value))
  }
}

const collapseAll = () => {
  Object.keys(expandedSections.value).forEach(section => {
    expandedSections.value[section] = false
  })
  if (process.client) {
    localStorage.setItem('debugToolbarSections', JSON.stringify(expandedSections.value))
  }
}

const dismissToolbar = () => {
  isVisible.value = false
  if (process.client) {
    localStorage.setItem('debugToolbarVisible', 'false')
  }
}

onMounted(() => {
  if (process.client) {
    isVisible.value = localStorage.getItem('debugToolbarVisible') === 'true'
    isMinimized.value = localStorage.getItem('debugToolbarMinimized') === 'true'
    const savedSections = localStorage.getItem('debugToolbarSections')
    if (savedSections) {
      expandedSections.value = JSON.parse(savedSections)
    }
    window.addEventListener('keydown', handleKeyDown)
  }
})

onUnmounted(() => {
  if (process.client) {
    window.removeEventListener('keydown', handleKeyDown)
  }
})
</script>

<style scoped>
.debug-toolbar {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  width: 300px;
  z-index: 9999;
}

.debug-toolbar.minimized {
  width: auto;
  padding: 10px;
  max-height: none;
  overflow: visible;
}

.minimized-content {
  cursor: pointer;
  font-size: 20px;
}

.toolbar-content {
  position: relative;
  font-size: 12px;
}

.close-button, .minimize-button {
  position: absolute;
  color: white;
  border: none;
  width: 12px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: transparent;
  padding: 0;
}

.close-button {
  top: -10px;
  right: -10px;
}

.minimize-button {
  top: -10px;
  right: 5px;
}

.section-header {
  cursor: pointer;
  margin-top: 5px;
  user-select: none;
}

.section-header:hover {
  background: rgba(255, 255, 255, 0.1);
}

.expand-icon {
  display: inline-block;
  width: 16px;
  font-size: 10px;
  margin-top: 5px;
}

.route-input-section {
  margin: 10px 0;
  display: flex;
  gap: 5px;
}

.route-input {
  flex: 1;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.go-button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.go-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

</style>
