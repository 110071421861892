import {ref} from "firebase/database"; // avoid using this import elsewhere. Always use this helper function!

export function dbRef(database, path) {
    if (typeof path !== "string") {
        throw new Error("path must be a string (did you forget the database param?");
    }
    if (process.env.NODE_ENV === "production") {
        // don't guard against production db use in production.
        return ref(database, path);
    }
    const myRef = ref(database, path);
    const str = myRef.toString();
    if (!isEmulator(str)) {
        const err = `DANGER WILL ROBINSON: You're using ${str}. This is not the emulator database!`;
        console.error(err);
        throw new Error(err);
    }
    return myRef;
}


export const isEmulator = (str) => {
    return str.startsWith("http://localhost:9000") || str.startsWith("http://127.0.0.1:9000");
}