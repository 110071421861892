import {useCurrentHost} from "~/composables/useCurrentDomain.js";
import callFirebaseFunction from "~/helpers/callFirebaseFunction.js";

export function gameImage(collectionItem) {
    // new-simmer only uses the first pattern (collectionItem.screenshotUrls?.[0]) and points to any full URL.
    // old-simmer used to put the screen alongside the game.
    return collectionItem.screenshotUrls?.[0] || `https://simmercdn.com/unity/${collectionItem.uid}/content/${collectionItem.guid}/screens/${collectionItem.selectedScreenshot}.png`;
}

export function getLink(collectionItem, username){
    return `/@${username}/${collectionItem.friendlyUrl ?? '~' + collectionItem.guid}`;
}

export function getDownloadFilename(collectionItem){
    return `${collectionItem.friendlyUrl ?? collectionItem.guid}.zip`;
}

export function formatDate(timestamp){
    return new Date(timestamp).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'America/Los_Angeles'
    });
}

// todo midpri: passing username is a bit of a hack.
export function userContentToCardData(userContent, username = null){
    username = username || userContent.userData.username;

    const primarySite = userContent.primarySite || 'simmer';
    const secondarySite = userContent.secondarySite;

    const displayOnSites = {};
    if (primarySite){
        displayOnSites[primarySite] = true;
    }
    if (secondarySite){
        displayOnSites[secondarySite] = true;
    }
    return {
        image: gameImage(userContent),
        title: userContent.projectName,
        subtitle: username,
        date: formatDate(userContent.timestamp),
        link: getLink(userContent, username),
        downloadFilename: getDownloadFilename(userContent),
        customLoading: userContent.customLoading,
        guid: userContent.guid,
        engine: userContent.engine,
        displayOnSites
    }
}

export function collectionItemArrayToCardDataArray(collectionItems){
    return collectionItems.map(item => userContentToCardData(item)) ?? [];
}

/* example input
    {
        "0": {
            "guid": "fcb043a8-a4ea-8182-1ebb-30d13198eec0"
        },
        "1": {
            "guid": "598d66aa-ea59-f804-2e6b-df2fcb37938e"
        },
        "2": {
            "guid": "33ce1bbc-c2ce-ceec-9c78-531dd6dac599"
        }
    }
 */
export function convertFirebaseArrayToJavascriptArray(firebaseArray){
    return Object.keys(firebaseArray)
        .sort((a, b) => a - b) // Ensure the order is maintained if needed
        .map(key => firebaseArray[key]);
}

export function throw404(){
    throw createError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
        fatal:true
    })
}

export function debounce(func, wait) {
    let timeout = null;

    return (...args) => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => func(...args), wait);
    };
}

export const getIframeDomain = () => {
    const host = useCurrentHost();
    if (host.includes('localhost')){
        return `http://localhost:8086`;
    }
    return 'https://i.simmer.io'
}

export const delay = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getGameUrl = (username, guid, friendlyUrl = null) => {
    return `/@${username}/${friendlyUrl || `~${guid}`}`;
}

export const getGameUrlWithDomain = (username, guid, friendlyUrl = null) => {
    return `${useCurrentDomain()}/@${username}/${friendlyUrl || `~${guid}`}`;
}

export const getDimensionsFromUserContent = (userContent) => {
    const defaultDimensions = {w : 960, h:600};
    if (!userContent){
        return defaultDimensions;
    }
    if (userContent.fixedWidth && userContent.fixedHeight){
        return {w: userContent.fixedWidth, h:userContent.fixedHeight};
    }
    else if (userContent.aspect){ // legacy aspect ratio for iframe games
        switch(userContent.aspect){
            case 'aspect16_9':
                return {w: 1920, h: 1080};
            case 'aspect4_3':
                return {w: 1024, h: 768};
            case 'aspect16_10':
                return {w: 1920, h: 1200};
        }
    }
    else if (userContent.unityData &&
        userContent.unityData.UNITY_WIDTH &&
        userContent.unityData.UNITY_HEIGHT){
        return {w: userContent.unityData.UNITY_WIDTH, h: userContent.unityData.UNITY_HEIGHT}
    }
    return defaultDimensions;
}

export const getIframeSrcFromUserContent = (userContent, user, game) => {
    if (!userContent) {
        return '';
    }
    if (userContent.engine === 'iframe'){
        return userContent.iframeData.url;
    }
    return `${getIframeDomain()}/@${user}/${game}`
}

export const isPaid = (userProfile) => {
    const isPaid = !!userProfile?.readOnlyUserData?.planId;
    console.log('isPaid', isPaid);
    return isPaid;
}

export const bytesToMB = (bytes, decimalPlaces = 0) => {
    const mb = bytes / (1024 * 1024);
    return Number(mb.toFixed(decimalPlaces));
}

export async function uploadImageFromDataUrl(dataUrl) {
    const uploadInfo = (await callFirebaseFunction("get_image_upload_url_and_cdn_path")).data;
    const signedUrl = uploadInfo.signed_url;
    const cdnPath = uploadInfo.cdn_path;

    // Get mime type from data URL
    const mimeType = dataUrl.split(';')[0].split(':')[1];

    // Convert data URL to blob
    const response = await fetch(dataUrl);
    const blob = await response.blob();

    // Upload to signed URL with correct content type
    await fetch(signedUrl, {
        method: 'PUT',
        body: blob,
        headers: {
            'Content-Type': mimeType
        }
    });

    return cdnPath;
}
